import { round } from "lodash";

export const convertCentsToDollars = (cents: number | undefined): number => {
  if (!cents) return 0;
  return cents / 100; // Деление на 100, чтобы перевести центы в доллары
};

export const convertDollarsToCents = (dollars: number | undefined): number => {
  if (!dollars) return 0;

  return Math.round(dollars * 100); // умножение на 100, чтобы перевести в центы
};

export const convertDateToSend = (
  dateString: string,
  hours: string,
  minutes: string
): string => {
  let date;

  // Проверяем, является ли строка уже форматированной датой
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(dateString)) {
    date = new Date(dateString + "Z");
  } else {
    // В противном случае, создаем объект Date из строки с часовым поясом
    date = new Date(dateString + " UTC");
  }

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Месяцы начинаются с 0, поэтому добавляем 1
  const day = date.getUTCDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}T${formatTime(
    hours
  )}:${formatTime(minutes)}:00`;

  return formattedDate;
};

const formatTime = (value: string) => {
  const intValue = parseInt(value);

  switch (true) {
    case isNaN(intValue):
      return "00";
    case intValue >= 0 && intValue <= 9:
      return `0${intValue}`;
    case intValue >= 10 && intValue <= 24:
      return `${intValue}`;
    default:
      return "00";
  }
};

export function getCurrentMonthAndYear() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  // return previous month data on 1st day of a new month
  if (day === 1) {
    return getNextOrPreviousMonth({ month, year }, false);
  }
  return { month, year };
}

export function getNextOrPreviousMonth(
  { month, year }: any,
  isNextMonth: boolean
) {
  const date = new Date(year, month - 1); // Subtracting 1 to get zero-based index for month

  if (isNextMonth) {
    date.setMonth(date.getMonth() + 1);
  } else {
    date.setMonth(date.getMonth() - 1);
  }

  const nextOrPreviousMonth = {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };

  return nextOrPreviousMonth;
}

export const calculateProfit = (winloss: number): number => {
  return winloss * -1;
};

export const calculateBonus = (profit: number, rev_share: number): number => {
  let sum = (profit * rev_share) / 100;

  return +sum ? +sum.toFixed(2) : +sum;
};

export const calculateTotal = (data: any[], key: string): number => {
  let sum = data?.reduce((sum, item) => sum + ((item[key] as number) ?? 0), 0);

  return +sum ? +sum.toFixed(2) : +sum;
};

export const formatNumber = (num: number) => {
  return round(num, 2);
};

export const getHoursWord = (hours: number): string => {
  const lastDigit = hours % 10;
  const lastTwoDigits = hours % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return hours + " час";
  } else if (
    [2, 3, 4].includes(lastDigit) &&
    ![12, 13, 14].includes(lastTwoDigits)
  ) {
    return hours + " часа";
  } else {
    return hours + " часов";
  }
};

export const checkDatePassed = (dateString: string) => {
  const nowUtc = new Date().toISOString();

  return nowUtc > dateString ? true : false;
};

export const convertDateToString = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const getTronTransactionId = (url: string) => {
  const transactionPart = url.split("/transaction/")[1];
  if (transactionPart) {
    const transactionId = transactionPart.split("?")[0];
    return transactionId;
  }
  return null;
};

import { FC, useEffect, useMemo } from "react";
import { Deal } from "../../core/_models";
import { useAuth } from "../../../auth";
import { useIntl } from "react-intl";
import { useGetOtherUserData } from "../../core/_requests";
import { convertCentsToDollars, getHoursWord } from "../../../../utils";
import { KTIcon } from "../../../../../_metronic/helpers";
import { getCategoryName } from "../../../components/helpers";

interface Props {
  deal: Deal;
  description: any;
}

export const DealDataCardBody: FC<Props> = ({ deal, description }) => {
  const intl = useIntl();
  const VERIFICATION = intl.formatMessage({ id: "DEAL_CARD_VERIFICATION" });
  const DEPOSIT = intl.formatMessage({ id: "DEAL_CARD_DEPOSIT" });

  const categoryName = useMemo(() => {
    return getCategoryName(intl, deal?.category.name);
  }, [intl, deal?.category.name]);

  const { currentUser } = useAuth();

  const {
    mutate: getSellerUser,
    data: dataSeller,
    isLoading: isDataSellerLoading,
  } = useGetOtherUserData();

  const {
    mutate: getBuyerUser,
    data: dataBuyer,
    isLoading: isDataBuyerLoading,
  } = useGetOtherUserData();

  useEffect(() => {
    if (deal?.seller?.uuid) getSellerUser(deal?.seller.uuid);
  }, [deal?.seller?.uuid]);

  useEffect(() => {
    if (deal?.buyer?.uuid) getBuyerUser(deal?.buyer.uuid);
  }, [deal?.buyer?.uuid]);

  const getMessage = (lock: string) => {
    switch (lock) {
      case "replacement":
        return intl.formatMessage({ id: "ACCOUNT_REPLACEMENT" });
      case "return":
        return intl.formatMessage({ id: "ACCOUNT_RETURN" });
      case "payment":
        return intl.formatMessage({ id: "ACCOUNT_PAYMENT_SELLER" });
      case "compensation":
        return (
          intl.formatMessage({ id: "ACCOUNT_COMPENSATION" }) +
          ": " +
          description?.inputLock
        );
      case "other":
        return description?.inputLock;
      default:
        return "-";
    }
  };

  return (
    <>
      <div className="">
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">Продавец</span>
          <div className="col-lg-8 fv-row">
            {isDataSellerLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <a
                href={`/apps/profile/overview/${deal?.seller.uuid}`}
                className="link fs-6 fw-semibold"
              >
                {dataSeller?.telegram.username} id: {dataSeller?.telegram.id}
              </a>
            )}
          </div>
        </div>
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
            Покупатель{" "}
          </span>
          <div className="col-lg-8 fv-row">
            {isDataBuyerLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <a
                href={`/apps/profile/overview/${deal?.buyer.uuid}`}
                className="link fs-6 fw-semibold"
              >
                {dataBuyer?.telegram.username} id: {dataBuyer?.telegram.id}
              </a>
            )}
          </div>
        </div>
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">Категория</span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {categoryName}
          </span>
        </div>
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">Название</span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {deal?.name}
          </span>
        </div>
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
            Стоимость USDT
          </span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {convertCentsToDollars(deal?.amount)}
          </span>
        </div>

        {description?.commission === "buyer" ? (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Комиссия сервиса (покупатель) USDT
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {convertCentsToDollars(Number(deal?.fee?.buyer))}
            </span>
          </div>
        ) : description?.commission === "salesman" &&
          deal?.seller?.uuid === currentUser?.uuid ? (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Комиссия сервиса (продавец) USDT
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {convertCentsToDollars(Number(deal?.fee?.seller))}
            </span>
          </div>
        ) : description?.commission === "%" ? (
          <>
            <div className="row mb-9 align-items-center mb-lg-10">
              <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
                Комиссия сервиса (покупатель) USDT
              </span>
              <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
                {convertCentsToDollars(Number(deal?.fee?.buyer))}
              </span>
            </div>

            <div className="row mb-9 align-items-center mb-lg-10">
              <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
                Комиссия сервиса (продавец) USDT
              </span>
              <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
                {convertCentsToDollars(Number(deal?.fee?.seller))}
              </span>
            </div>
          </>
        ) : null}

        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
            {categoryName === VERIFICATION
              ? "Название или ссылка"
              : "Описание и характеристики"}
          </span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {description?.description}
          </span>
        </div>

        {description?.country && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">Страна</span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.country}
            </span>
          </div>
        )}
        {description?.citizenship && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Гражданство владельца аккаунта
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.citizenship}
            </span>
          </div>
        )}
        {description?.docs && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Документы к аккаунту
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.docs}
            </span>
          </div>
        )}

        {description?.action && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Что предстоит сделать?
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.action}
            </span>
          </div>
        )}

        {description?.demo && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Демонстрация
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.demo}
            </span>
          </div>
        )}

        {description?.test && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Тестовые транзакции на аккаунте
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.test}
            </span>
          </div>
        )}

        {description?.lock && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              Если во время сделки аккаунт будет заблокирован или ограничен,
              тогда как поступим?
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {getMessage(description?.lock)}
            </span>
          </div>
        )}

        {description?.proof && (
          <div className="row mb-9 align-items-center mb-lg-10">
            <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
              {categoryName === DEPOSIT
                ? "При каких обстоятельствах кому идёт выплата?"
                : categoryName === VERIFICATION
                ? "При неудачной верификации"
                : "Доказательства успешного завершения сделки"}
            </span>
            <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
              {description?.proof}
            </span>
          </div>
        )}
        <div className="row mb-9 align-items-center mb-lg-10">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
            Необходимый срок для полного завершения сделки
          </span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {deal?.protect && getHoursWord(deal?.protect)} с момента открытия
            сделки
          </span>
        </div>
        <div className="row mb-12 align-items-center mb-lg-15">
          <span className="col-lg-4 mb-2 mb-lg-0 fw-bold fs-4">
            Любая другая важная информация
          </span>
          <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
            {description?.others}
          </span>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-4 text-gray-700 mb-2 mb-lg-0 fw-bold fs-5 d-flex ">
          <div className="me-2">UUID сделки</div>
          <div className="d-flex align-items-center text-hover-primary  ">
            <KTIcon iconName="verify" className="fs-1 me-1 text-primary " />
          </div>
        </div>
        <span className="text-gray-600 fs-6 fw-semibold col-lg-8 fv-row">
          {deal?.uuid}
        </span>
      </div>
    </>
  );
};
